<template>
    <b-col xl="6" md="9">
        <h6 class="mb-3">Persona jurídica con operaciones y/o activos en varias jurisdicciones incluyendo Panamá</h6>

        <LegalEntityProperty label="Fecha de desvinculación"
            :value="parseDateLocal(legalEntity.disableLegalEntityReason.date)"
            v-if="legalEntity.disableLegalEntityReason != null" />

        <LegalEntityProperty label="Motivo de desvinculación" :value="legalEntity.disableLegalEntityReason.details"
            v-if="legalEntity.disableLegalEntityReason != null" />

        <LegalEntityProperty label="Fecha de registro" :value="parseDateLocal(legalEntity.creationDate)" />

        <LegalEntityProperty label="Agente Residente" :value="legalEntity.residentAgent.toUpperCase()" />

        <LegalEntityProperty label="CUR" :value="legalEntity.cur" />

        <LegalEntityChecks :legalEntity="legalEntity" />

        <LegalEntityProperty label="Número de ficha o folio" :value="legalEntity.folioNumber" />

        <LegalEntityProperty label="Nombre completo" :value="legalEntity.name" />

        <LegalEntityProperty label="RUC" :value="legalEntity.ruc" />

        <LegalEntityProperty label="Fecha de inscripción" :value="parseDate(legalEntity.enrollmentDate)" />

        <LegalEntityProperty label="Dirección" :value="legalEntity.direction" />

        <LegalEntityProperty label="Actividad principal" :value="legalEntity.principalActivity" />

        <LegalEntityProperty label="Servicio de accionista Nominal"
            :value="legalEntity.nominalShareholderService ? 'Si' : 'No'" />

        <LegalEntityProperty label="Servicio de director Nominal"
            :value="legalEntity.nominalDirectorService ? 'Si' : 'No'" />

        <LegalEntityProperty label="Servicio de apoderado" :value="legalEntity.proxyService ? 'Si' : 'No'" />

        <LegalEntityPublicRegister :legalEntity="legalEntity" />
    </b-col>
</template>

<script>
import moment from "moment";
import LegalEntityProperty from "../legal-entity-properties/LegalEntityProperty.vue";
import LegalEntityChecks from "../legal-entity-properties/LegalEntityChecks.vue";
import LegalEntityPublicRegister from "../legal-entity-properties/LegalEntityPublicRegister.vue";

export default {
    name: "mixed-operations-legal-entity",
    components: {
        LegalEntityProperty,
        LegalEntityChecks,
        LegalEntityPublicRegister
    },
    props: {
        legalEntity: {
            type: Object,
            required: true,
        },
    },
    methods: {
        parseDate(date) {
            return moment(date).format("DD/MM/YYYY");
        },
        parseDateLocal(date) {
            return moment.utc(date).local().format("DD/MM/YYYY hh:mm:ss A");
        },
    },
};
</script>