import axios from 'axios';

const backendApiUrl = process.env.VUE_APP_BACKEND_API_URL;
const baseURL = `${backendApiUrl}api`;

export async function checkPublicRegister(folio, societyType) {
    try {
        const uri = `${baseURL}/integration/society/${folio}/${societyType}`;
        const response = await axios.get(uri);

        if (response?.data?.status != null) {
            return { code: 200, data: response.data }; 
        }

        return { code: 502, data: "No hay datos disponibles" }; 
    } catch (e) {
        return { code: e.response.data?.code ?? e.response?.status, data: e.response.data?.message ?? null };
    }
}