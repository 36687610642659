<template>
    <b-row class="mb-2">
        <b-col sm="4">
            <label class="details-title">
                Estado del Registro Público
            </label>
        </b-col>
        <b-col>
            <span class="details-title-value">{{ status }}</span>
        </b-col>
    </b-row>
</template>

<script>
import { checkPublicRegister } from "../../../services/integration";

export default {
    name: "legal-entity-public-register",
    props: {
        legalEntity: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            status: null,
        }
    },
    mounted() {
        this.getStatus();
    },
    methods: {
        async getStatus() {
            const result = await checkPublicRegister(this.legalEntity.folioNumber, this.legalEntity.societyType);
            
            if (result.code === 200 && result?.data?.status) {
                this.status = result.data.status;
            } else {
                this.status = "Lo se siento, no se pudo consultar.";
            }
        }
    }
};
</script>