<template>
    <b-row class="mb-2">
        <b-col sm="4">
            <label class="details-title">
                {{ $t("general.society-type") }}
            </label>
        </b-col>
        <b-col>
            <span class="details-title-value">{{ societyType }}</span>
        </b-col>
    </b-row>
</template>

<script>
export default {
    name: "legal-entity-checks",
    props: {
        legalEntity: {
            type: Object,
            required: true,
        },
    },
    computed: {
        societyType() {
            let societyType = this.legalEntity.societyType;
            if (societyType !== undefined && societyType !== null) {
                return societyType;
            }

            return "-";
        },
    },
};
</script>