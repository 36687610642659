<template>
    <b-card no-header no-body class="rounded-0 shadow-sm border-0">
        <b-table small stacked="lg" :busy.sync="loading" :items="items" :fields="fields" show-empty
            empty-text="No hay jurisdicciones que mostrar">
            <template #table-busy>
                <div class="text-center text-primary my-2">
                    <b-spinner class="align-middle"></b-spinner>
                </div>
                <div class="text-center text-primary my-2">
                    <strong>Cargando jurisdicciones</strong>
                </div>
            </template>
        </b-table>
    </b-card>
</template>

<script>
export default {
    name: "jurisdictions-lists",
    components: {
    },
    props: {
        legalEntity: {
            type: Object,
            required: true,
        },
        noColumnNit: {
            type: Boolean,
            require: false
        },
    },
    computed: {
        fields() {
            return this.noColumnNit ? this.columnsWithoutNit : this.columnsWithNit;
        }
    },
    data() {
        return {
            loading: false,
            columnsWithoutNit: [
                { key: "jurisdiction", label: "Jurisdicción" },
            ],
            columnsWithNit: [
                { key: "jurisdiction", label: "Jurisdicción", thStyle: { width: "25%" }, },
                { key: "nit", label: "NIT" },
            ],
            items: [],
        };
    },
    async mounted() {
        this.items = this.legalEntity.jurisdictions;
    },
    methods: {
        refresh(legalEntity) {
            this.items = legalEntity.jurisdictions;
        }
    }
};
</script>